import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.page.notice.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import ClassicEditor from "src/libs/ckeditor/ckeditor";
import moment from "moment";

@Component({
    selector: 'wiz-page-page-notice-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.page.notice.item/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.pre-label {
  font: normal normal bold 18px/21px NanumSquareOTF;
}
.pre-label img {
  filter: invert(88%) sepia(0%) saturate(1%) hue-rotate(220deg) brightness(78%) contrast(88%);
  width: 40px;
  height: 20px;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.content {
  padding: 40px 25px 25px 25px;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}

.btn-pre {
  margin: 0 auto;
  max-width: 1440px;
}

.btn-apply {
  margin-top: 30px;
  margin-left: 30px;
  background-color: #5263ff;
  color: white;
}

.btn-list {
  margin-top: 30px;
  background-color: #D1D1D1;
}

.wiz-form {
  margin: 0 auto;
  max-width: 1440px;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-read {
  background-color: #FF5933;
  color: white;
  border-color: white;
  padding: 12px 28px;
  margin-top: 12px !important;
}
@media (max-width: 768px) {
  .btn-read {
    margin-top: 12px !important;
  }
}

.opt {
  border: 1px solid #72787F;
}
.opt:hover {
  color: #FF5933;
  border: 1px solid #FF5933;
  background-color: #FFF2ED;
}
.opt.active {
  background-color: #FF5933;
  color: #FFF;
  border: 1px solid #FF5933;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.header .text-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
}

.text-container {
  width: 100%;
  margin: 0 auto;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
  width: 1440px;
  min-height: 300px;
  margin: 0 auto;
}
.content-container .content-body {
  max-width: 1180px;
  margin: 40px auto 60px auto;
  height: 100%;
  text-align: left;
}`],
})
export class PagePageNoticeItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  public location: Location,@Inject( Router)  private router: Router) {

        this.post_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
    }

    private previous_post: any;
    private next_post: any;
    public contentEditor: any;
    public post_id: any;
    public category: any;
    public item: any = {
        user_id: '',
        title: '',
        content: '',
        category: '',
        status: '',
        created: '',
        updated: '',
        files: []
    };

    public rows: any;
    public tab: string = "content";

    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.post_id, category: this.category });
        let { item, rows } = data;
        this.item = item;
        this.item.files = JSON.parse(data.item.files.replace(/'/g, '"'));
        this.rows = rows;

        let previous_post = null;
        let next_post = null;


        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];

            if (row.created < item.created) {
                if (previous_post === null || (previous_post && row.created > previous_post.created)) {
                    previous_post = row;
                }
            }
            else if (row.created > item.created) {
                if (next_post === null || (next_post && row.created < next_post.created)) {
                    next_post = row;
                }
            }
            if (previous_post && next_post) {
                break;
            }
        }

        this.previous_post = previous_post;
        this.next_post = next_post;

        this.item.created = moment(this.item.created).format("YYYY-MM-DD");
        this.item.updated = moment(this.item.updated).format("YYYY-MM-DD");

        const CONTENT_ID = 'textarea#content';
        const Content = this.item.content || '';
        this.contentEditor = await this.createEditor(CONTENT_ID, Content);

        await this.service.render();
    }
    public async createEditor(selector: string, content: string) {
        const opt = {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ['MediaEmbedToolbar', 'Markdown'],
            table: ClassicEditor.defaultConfig.table,
        };

        const editorInstance = await ClassicEditor.create(document.querySelector(selector), opt);

        if (content) {
            editorInstance.data.set(content);
        } else {
            editorInstance.data.set('');
        }
        editorInstance.isReadOnly = true;
        editorInstance.ui.view.toolbar.element.style.display = 'none';

        const editorElement = editorInstance.ui.getEditableElement();

        editorElement.style.border = 'none';
        editorElement.style.height = 'auto';
        editorElement.style.setProperty('padding-bottom', '0', 'important');
        editorElement.style.display = 'block';

        return editorInstance;
    }
    public desc() {
        let item: any = this.item;
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }

    public route(page) {
        if (this.category) {
            location.href = `/notice/${this.category}/${page}`
        }
        else {
            location.href = `/notice/${page}`
        }
    }
    public async tabChange(tab: string) {
        this.tab = tab;
        await this.service.render();
    }
    public back() {
        this.location.back();
    }
}

export default PagePageNoticeItemComponent;