import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.ticketing');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-portal-kreonetadm-ticketing',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.kreonetadm.ticketing/view.scss */
.workspace {
  padding: 0 24px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 999;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.list-header {
  margin: 0 auto;
  width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.list-header .btn-blue {
  width: 132px;
  height: 44px;
}

.search {
  background: white;
}
.search i {
  width: 18px;
  height: 18px;
  color: white;
}
.search .form-control {
  border-radius: 10px 0 0 10px;
  width: 349px;
  height: 44px;
  border: none;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  padding-left: 25px;
  z-index: 3;
  color: var(--unnamed-color-6d6d6d);
  text-align: left;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-nanumsquareotf);
  box-shadow: none;
}
.search .btn-search {
  width: 44px;
  height: 44px;
  border: none;
  background: #5263FF 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  z-index: 3;
}

.pagenation {
  margin-top: 50px;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal normal 18px/21px NanumSquareOTF;
  text-align: center;
}

.btn-blue {
  width: 132px;
  height: 44px;
  text-align: center;
  border-radius: 10px;
}

.work-container {
  display: table;
  width: max-content;
  height: 100%;
  overflow: hidden;
}
.work-container .work-group {
  display: table-cell;
  width: 320px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--wc-border);
}
.work-container .work-group .work-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-top: 2px;
}
.work-container .work-group .work-header {
  display: flex;
  height: 46px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid var(--wc-border);
}
.work-container .work-group .work-header input {
  font-size: 16px;
  font-family: "main-eb";
}
.work-container .work-group .work-header .btn {
  min-width: 24px;
}
.work-container .work-group .work-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .work-container .work-group .work-body {
    padding: 0 32px;
  }
}
.work-container .btn-action-move {
  cursor: grab !important;
}
.work-container .label-container {
  height: 100%;
  display: table;
  width: max-content;
  overflow: hidden;
}
.work-container .issue-container {
  min-height: 160px;
}

.empty img {
  width: 80%;
  max-width: 240px;
}

.issue-item {
  border-radius: 16px;
  margin-bottom: 12px;
}
.issue-item:first-child {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .issue-item {
    margin-bottom: 16px;
  }
}

.status-items {
  font-size: 13px;
}

.issue-layout {
  position: relative;
  width: 100%;
  height: 100%;
}

.scroll-left {
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-left {
    display: none;
  }
}
.scroll-left:hover {
  background-color: var(--wc-border);
}

.scroll-right {
  position: absolute;
  top: 49%;
  right: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-right {
    display: none;
  }
}
.scroll-right:hover {
  background-color: var(--wc-border);
}

.card {
  max-width: 1570px;
  width: 95%;
  height: 95%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
}

.row {
  padding: 1rem 2rem;
}
.row .form-select {
  border: 1px solid #929DAB;
  border-radius: 10px;
  width: 132px;
  height: 44px;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.table-responsive {
  padding: 0 1rem;
}

.table-last {
  max-width: 1509px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  font: normal normal bold 17px/19px NanumSquareOTF;
  background: none;
  vertical-align: middle;
  color: #000000;
}
.table-last tbody tr {
  width: 1509px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.table-last tbody tr.selected-row {
  font: normal normal bold 18px/21px NanumSquareOTF;
}
.table-last tbody tr td {
  min-width: 8rem;
  height: 60px;
}`],
})
export class PortalKreonetadmTicketingComponent implements OnInit, OnDestroy {
    constructor(@Inject( Service)         public service: Service,@Inject( Project    )         public project: Project    ) {
    }
    @Input() institute_ns: string = '';
    public post_id: string = '';
    public items: any = [];
    public loaded: boolean = false;
    private status = "default";
    public search_form: any = { page: 1, ns: this.institute_ns, status: this.status };
    public loading = true;
    public user_name = '';
    public search_word = '';
    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };


    @ViewChild('container')
    public workspace: ElementRef;

    public socket: any = null;
    public labels: any = [];

    public readOnly: boolean = true;

    public cache: any = {
        label: '',
        loaded: false,
        issues: {},
        hiddenIssues: {}
    };

    public issue: any = {
        id: null,
        modal: false,
        event: {}
    };

    public config: any = {
        labelSorted: {
            animation: 0,
            handle: '.btn-action-move'
        },
        issueSorted: {
            animation: 0,
            handle: '.card',
            group: 'issue'
        }
    };
    public async readDetail(id: string) {
        let { code, data } = await wiz.call("read", { user: id });
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public async loadChange(loading: boolean) {
        this.loading = loading;
        this.pageLoad(1);
    }


    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.");

        }
        this.pagenation.page = 1;
        await this.load();
    }

    private async move(index: number) {
        this.pagenation.page = index;
        await this.load();
    }

    public async load() {
        this.loaded = false;
        const { code, data } = await wiz.call("load", { ns: this.institute_ns, page: this.pagenation.page, status: this.status, text: this.search_word });
        if (code != 200) return;
        let { rows, lastpage } = data;
        this.items = rows;
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }
    private detail(id: string, user_name: string) {
        this.post_id = id;
        this.user_name = user_name;
        this.loading = false;
    }
    private changeStatus() {
        this.search_word = '';
        this.pageLoad(1);
    }
    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }
}

export default PortalKreonetadmTicketingComponent;