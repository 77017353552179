import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.apply');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-apply',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.apply/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border-radius: none;
  display: flex;
  flex-direction: column;
}

.season-page::-webkit-scrollbar {
  display: none;
}`],
})
export class PageApplyComponent implements OnInit, OnDestroy, DoCheck {
    public ITEM: any;

    constructor(@Inject( Service) public service: Service) {
        this.ITEM = WizRoute.segment.item;
    }

    public async ngDoCheck() {
        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.service.render();
    }

}

export default PageApplyComponent;