import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.institute');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-institute',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.institute/view.scss */
.navbar {
  display: block;
  background: #fff;
  padding: 0;
}
.navbar .section {
  display: flex;
  align-items: center;
}
.navbar .section:last-child {
  border-top: 1px solid var(--wc-border);
}
.navbar .section .container {
  min-height: 52px;
  align-items: center;
}
.navbar .section.section-main {
  background-color: var(--wc-blue);
}
.navbar .section.section-main .navbar-brand span {
  color: #fff;
}
.navbar .section.section-main .navbar-brand .page-icon {
  background-color: #fff;
  color: var(--wc-blue);
}
.navbar .nav-item {
  cursor: pointer;
}
.navbar .nav-item.active:after {
  bottom: -2px;
}
.navbar .container {
  display: flex;
  justify-content: initial;
}
.navbar .navbar-brand img {
  height: 28px;
}
.navbar .navbar-brand span {
  color: var(--wc-gray);
  font-size: 18px;
}
.navbar .navbar-toggler {
  color: #fff;
  margin-right: 12px;
}
.navbar .nav-link {
  color: var(--wc-text);
}
.navbar a {
  color: var(--wc-text);
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar {
    min-height: 0;
  }
  .navbar .section .container {
    min-height: 0;
  }
  .navbar .navbar-brand span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbar .nav-item {
    min-height: 48px;
  }
  .navbar .nav-item.active:after {
    bottom: 0;
  }
  .navbar .section-sub {
    background: var(--wc-blue);
  }
  .navbar .section-sub .nav-link {
    color: #fff;
  }
  .navbar .section-sub .navbar-collapse.collapse.show {
    height: 100vh;
  }
}

.dropdown-menu {
  top: 48px;
  right: 0;
}

.v-center {
  display: flex;
  align-items: center;
}`],
})
export class ComponentNavInstituteComponent implements OnInit {
    @Input() data: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

}

export default ComponentNavInstituteComponent;