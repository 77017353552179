import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.devices');
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonetadm-devices',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.kreonetadm.devices/view.scss */
.container {
  height: max-content;
  width: 1800px;
}

.table-vcenter {
  width: 100%;
  margin: 0 auto;
}

.card {
  width: 90%;
  margin: 0 auto;
}

.location-list {
  width: 90%;
  text-align: center;
}

.wiz-form-label {
  font-size: 15px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  z-index: 10;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.form-control {
  padding: 0.5625rem 0.75rem !important;
  font-size: 0.875rem !important;
}`],
})
export class PortalKreonetadmDevicesComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }
    public institutes = [];
    public user_roles = [
        '-',
        '관리책임자',
        '전산담당자',
        '연회비담당자',
        '연구책임자',
        '실무연구자'
    ];
    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public listloaded: boolean = false;
    public list: any = [];
    public status: string = '대전';
    public locations: any = [];
    public total: any;
    public sidebar: any = null;
    private search_word = "";
    public newDevice: any = {
        // category: '',
        location: '',
        device: '',
        ifDescr: '',
        ifName: '',
        ifAlias: ''
    };

    public pagenation: any = {
        page: 1,
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        if (status == "없음") status = "";
        this.listloaded = false;

        let { code, data } = await wiz.call("list", { page: page, text: this.search_word, status: status });
        if (code != 200) return;
        this.pagenation.page = page;
        this.list = data.rows;
        this.pagenation.start = Math.floor((page - 1) / 10) * 10 + 1;;
        this.pagenation.end = data.lastpage;
        this.listloaded = true;
        if (status == "") status = "없음";
        this.status = status;
        this.locations = data.locations;
        this.total = data.total;
        await this.service.render();
    }

    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load(p);
    }

    public async update() {
        const { code, data } = await wiz.call("update", { data: JSON.stringify(this.list) })
        if (code == 200) {
            await this.alert('저장되었습니다.', 'success')
        }
    }

    public async eachUpdate(id, device) {
        const { code, data } = await wiz.call("eachUpdate", device)
        if (code == 200) {
            await this.alert('저장되었습니다.', 'success')
        }
    }

    public async sidebarOpen() {
        this.sidebar = 'info';
        await this.service.render();
    }

    public async sidebarClose() {
        this.sidebar = null;
        this.newDevice = {};
        await this.service.render();
    }

    public async addDevice() {
        if (Object.entries(this.newDevice).some(([key, value]) => key !== 'ifAlias' && value === '')) {
            await this.alert('ifAlias를 제외한 모든 정보를 입력해주세요.', 'error');
            return;
        }
        const { code, data } = await wiz.call("addDevice", this.newDevice)
        if (code == 200) {
            let res = await this.alert('새로운 장비가 추가되었습니다.', 'success')
            if (res) {
                await this.sidebarClose();
                await this.load(1, this.status);
            }
        }
    }
}

export default PortalKreonetadmDevicesComponent;