import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.admin.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-apply-admin-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.apply.admin.list/view.scss */
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
  margin-top: auto;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

span {
  font: normal normal normal 16px SUIT;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}

.workspace-list-header {
  max-width: 1570px;
  width: 95%;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal normal 18px/21px NanumSquareOTF;
  text-align: center;
}

.btn-blue {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #FE5A33;
  background: rgba(254, 90, 51, 0.04);
  border-color: #FE5A33;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

.container {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
}

.card {
  max-width: 1570px;
  width: 95%;
  height: 93%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
}

.row {
  padding: 1rem 2rem;
}
.row .form-select {
  border: 1px solid #929DAB;
  border-radius: 10px;
  width: 132px;
  height: 44px;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.table-responsive {
  padding: 0 1rem;
}

.table-last {
  max-width: 1509px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  font: normal normal bold 17px/19px NanumSquareOTF;
  background: none;
  vertical-align: middle;
  color: #000000;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 60px;
  min-width: 8rem;
  font: normal normal normal 18px/21px NanumSquareOTF;
}`],
})
export class PortalApplyAdminListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public categoryMap: any = { notice: '공지사항', report: '보고서', article: '보도자료' };
    public items: any = [];
    public loaded: boolean = false;

    private status = "default";
    public pagenation = {
        start: 1,
        end: 1,
        current: 1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.pageLoad(1);
        await this.service.render();
    }

    public async load() {
        this.loaded = false;

        const { code, data } = await wiz.call("search", { page: this.pagenation.page, status: this.status });
        if (code != 200) return;
        this.items = data.rows;
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = data.lastpage;
        this.loaded = true;
        await this.service.render();
    }

    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }
}

export default PortalApplyAdminListComponent;