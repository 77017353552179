import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.institute.item');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-portal-kreonetadm-institute-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.kreonetadm.institute.item/view.scss */
.wiz-page {
  flex-direction: column;
}

.wiz-nav {
  max-width: 1570px;
  margin: 0px auto;
  width: 95%;
}

.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1570px;
  width: 95%;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}

.season-page::-webkit-scrollbar {
  display: none;
}

.season-nav {
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
  z-index: 11;
}
@media (max-width: 768px) {
  .season-nav {
    margin-top: 8px;
    margin-bottom: 12px;
  }
}
.season-nav .menu.highlight {
  background-color: #5263FF;
  color: white;
}
.season-nav .logo {
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 15px;
  padding: 0 24px;
  background-color: white;
  cursor: pointer;
}
.season-nav .logo span {
  font-size: 20px;
  margin-left: 24px;
  font-weight: 1000;
}
@media (max-width: 1440px) {
  .season-nav .logo span {
    display: none;
  }
}
.season-nav .menu {
  display: flex;
  align-items: center;
  height: 80px;
  width: 164px;
  padding: 0 24px;
  background-color: white;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  justify-content: center;
  margin: 0px 10px;
  position: relative;
}
.season-nav .menu:hover {
  background-color: #7e8af8;
  color: white;
}
.season-nav .menu span {
  margin-top: 10px;
}
.season-nav .menu h3 {
  font-size: 15px;
  font: normal normal bold 18px/22px Montserrat;
}
.season-nav .menubar {
  width: 80px;
  margin-right: 0 !important;
}
.season-nav .col {
  border: #5263FF 1px;
}
.season-nav .menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}
.season-nav .box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 130px;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.season-nav .box span {
  margin-top: 10px;
}
.season-nav .box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.season-nav .box button {
  padding: 0px;
  width: 170px;
  border: none;
  border-radius: 15px;
}
.season-nav .box button:active {
  color: white;
}
.season-nav .on {
  background-color: #5263FF;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}
.season-nav .dropdown {
  width: 170px;
  z-index: 1000;
  padding: 0;
}
.season-nav .dropdown .dropdown-box {
  display: none;
  position: absolute;
  left: -40px;
  top: 45px;
}
.season-nav .dropdown ul li {
  margin-top: 10px;
}
.season-nav .dropdown li {
  position: relative;
  text-align: center;
  list-style: none;
}
.season-nav .dropdown li:hover .dropdown-box {
  display: block;
}
.season-nav .dropdown:hover .dropdown-box {
  display: block;
  padding: 0;
}
.season-nav .dropdown a {
  display: block;
  height: 70px;
  width: 170px;
  padding: 24px;
  border-radius: 15px;
  color: black;
  background: white;
  text-decoration: none;
}
.season-nav .dropdown a.active,
.season-nav .dropdown a:hover {
  background: #FEF2ED;
  color: #5263FF;
  border: 1px #5263FF solid;
  transition: 0.2s;
}

.nav-toggler {
  display: none;
}

.nav-collapse {
  display: block;
}

@media (max-width: 768px) {
  .nav-toggler {
    display: block;
  }
  .nav-collapse {
    display: none;
  }
}
.nav-menus {
  display: none;
  color: #fff;
}
@media (max-width: 768px) {
  .nav-menus {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
  }
  .nav-menus.closed {
    display: none;
  }
}
.nav-menus .close-btn {
  width: 100%;
  padding-top: 24px;
  padding-right: 36px;
  text-align: right;
  font-size: 36px;
}
.nav-menus .menu-item-container {
  padding: 24px 48px;
  z-index: 10;
}
.nav-menus .menu-item-container .menu-item {
  color: #fff;
  padding: 24px 12px;
  padding-top: 24px;
  padding-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  font-size: 18px;
}
.nav-menus .lang {
  padding: 24px 48px;
  font-size: 18px;
}
.nav-menus .lang .col {
  text-align: center;
}`],
})
export class PortalKreonetadmInstituteItemComponent implements OnInit, OnDestroy, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public isMenuCollapsed = true;
    @Input() institute_ns: string = '';
    @Input() category: any;
    @Input() ID: string;

    public async ngOnInit() {
        await this.service.init();

        await this.service.auth.allow(true, '/');
        await this.loader.load();
        this.loaded = true;
        await this.service.render();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }


    public tab: any = {
        value: 'info',
        is: (val: string) => {
            return this.tab.value == val;
        },
        change: async (val: string) => {
            this.tab.value = val;
            await this.service.render();
        }
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public loader: any = {
        loaded: false,
        data: {},
        load: async (reload: boolean = false) => {
            if (!reload && this.loader.loaded)
                return this.loader.data;
            this.loader.loaded = false;
            await this.service.render();
            const { code, data } = await wiz.call("load", { ns: this.institute_ns });
            if (code != 200) {
                await this.service.href("/admin/info/institutes");
                return;
            }
            try {
                data.info.created = moment(data.info.created).format("YYYY-MM-DD");
            } catch (e) {
            }

            let values = {};
            for (let i = 0; i < data.network.length; i++) {
                let net = data.network[i];
                if (net.annual_fee && net.annual_fee * 1 > 0)
                    values[net.name] = net.annual_fee * 1 / 10000;
            }

            let chartData: any = { annual_fee: {} };
            chartData.annual_fee.labels = [];
            chartData.annual_fee.series = [];
            for (let key in values) {
                chartData.annual_fee.labels.push(key)
                chartData.annual_fee.series.push(values[key]);
            }
            data.chart = chartData;
            for (let key in data)
                this.loader.data[key] = data[key];
            this.loader.loaded = true;
            await this.service.render();
            return data;
        }
    };
    public async toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
    }

}

export default PortalKreonetadmInstituteItemComponent;