import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.widget.institute.docs');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-portal-kreonetadm-widget-institute-docs',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.kreonetadm.widget.institute.docs/view.scss */
.content {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}`],
})
export class PortalKreonetadmWidgetInstituteDocsComponent implements OnInit, OnDestroy, DoCheck {
    @Input() institute_ns: string = '';
    @Input() doc_id: string = '';

    constructor(@Inject( Service) public service: Service,@Inject( Project)  public project: Project) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
        await this.project.bind(this.service);
        await this.project.init(this.institute_ns);
        await this.project.init(this.doc_id);
        this.loaded = true;
        await this.service.render();
    }

    public loaded: boolean = false;

    public async load() {
        if (this.service.institute) return;
        const { code, data } = await wiz.app('page.institutes.item').call("load", { ns: this.institute_ns });
        if (code != 200) {
            this.location.back();
            return;
        }
        try {
            data.info.create = moment(data.info.create).format("YYYY-MM-DD");
        } catch (e) {
        }
        this.service.institute = data;
        await this.service.render();
    }

    public async ngOnDestroy() {
        await this.project.revert();
        await this.service.render();
    }
}

export default PortalKreonetadmWidgetInstituteDocsComponent;