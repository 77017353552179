import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.notice');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import $ from 'jquery';

@Component({
    selector: 'wiz-page-notice',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.notice/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px auto;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.season-page::-webkit-scrollbar {
  border-radius: 0px;
  display: none;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.header .text-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
}

.content {
  padding: 80px 25px 25px 25px;
  text-align: center;
  align-items: center;
}

.content-container {
  display: flex;
  justify-content: center;
}

.sticky-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 8px 48px;
  position: sticky;
}

.btn {
  height: 60px;
  width: 179px;
  border-bottom: #5263FF 1px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.search {
  background: white;
}
.search i {
  width: 18px;
  height: 18px;
  color: white;
}
.search .form-control {
  border-radius: 10px 0 0 10px;
  width: 349px;
  height: 59px;
  border: none;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  padding-left: 25px;
  z-index: 3;
  color: var(--unnamed-color-6d6d6d);
  text-align: left;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-nanumsquareotf);
  box-shadow: none;
}
.search .btn-search {
  width: 59px;
  height: 59px;
  border: none;
  background: #5263FF 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  z-index: 3;
}

.featured-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px 90px;
  border-radius: 10px;
}

.card {
  width: 1440px;
  margin: 0 auto;
  border: none;
}

.table-responsive {
  max-width: 1440px;
  margin: 0 auto;
}

.table-last {
  margin: 0 auto;
  width: 100%;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  font: normal normal bold 17px/19px NanumSquareOTF;
}
.table-last tbody tr {
  width: 1440px;
  height: 60px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  margin: auto;
  vertical-align: middle;
  height: 60px;
  font: normal normal normal 20px/23px NanumSquareOTF;
  text-align: left;
}

h4 {
  color: white;
}

.tabs {
  margin: 0 auto;
  height: 60px;
  width: 480px;
  overflow: hidden;
}

.tabs .tab-header {
  height: 50px;
  display: flex;
  align-items: center;
}

.tabs .tab-header > div {
  width: 179px;
  text-align: center;
  color: #888;
  cursor: pointer;
  font: normal normal normal 20px/20px NanumSquareOTF;
  text-transform: uppercase;
  outline: none;
}

.tabs .tab-header > div > i {
  display: block;
  margin-bottom: 5px;
}

.tabs .tab-header > div.active {
  color: #5263FF;
  font: normal normal bold 23px/20px NanumSquareOTF;
}

.tabs .tab-indicator {
  position: relative;
  width: 160px;
  height: 4px;
  background: #5263FF;
  left: 0px;
  border-radius: 999px;
  transition: all 500ms ease-in-out;
}`],
})
export class PageNoticeComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public category: any = '';
    public indicatorPosition: string = '0%';
    public items: any = [];
    public loaded: boolean = false;

    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };

    private search_word = "";

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        const { code, data } = await wiz.call("search", { page: this.pagenation.page, category: this.category, text: this.search_word });
        if (code != 200) return;
        let { rows, lastpage } = data;
        this.items = rows;
        this.pagenation.start = (parseInt(this.pagenation.page / 11) * 10) + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.", "error");
        }
        this.pagenation.page = 1;
        await this.load();
    }
    private async move(index: number) {
        this.pagenation.page = index;
        await this.load();
    }

    public async changeCategory(index: number, category: string) {
        this.category = category;
        this.pagenation.page = 1;
        this.search_word = "";
        this.indicatorPosition = `calc(calc(100% / 3) * ${index})`;
        this.service.render();
        // 카테고리가 변경되면, 검색어 reset
        await this.load();
    }

    public desc(item: any) {
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        text = $(text).text();
        if (text.length > 140) text = text.substring(0, 138) + '...';
        return text;
    }
    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }
}

export default PageNoticeComponent;