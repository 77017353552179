import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.community.list');
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import toastr from 'toastr';

@Component({
    selector: 'wiz-portal-community-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.community.list/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.page-header {
  margin-bottom: 20px;
}

.btrr {
  border-top-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.text-navy {
  color: #080D28;
}
.text-navy:hover {
  font-weight: bold;
  text-decoration: none;
}

a {
  cursor: pointer;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.btn-light {
  background: #6758F0;
  color: white;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.1607843137);
}

.br-10 {
  border-radius: 10px;
}

.search {
  border: 1px solid #6758F0;
  border-radius: 30px;
  background: #FFFFFF;
  width: 100%;
}
.search i {
  width: 18px;
  height: 18px;
}
.search .form-control {
  border-radius: 50px 0 0 50px;
  border-right: none;
  padding-left: 25px;
  z-index: 3;
  border-color: #6758F0;
  box-shadow: none;
}
.search .btn-search {
  border-radius: 0 50px 50px 0 !important;
  border-left: none;
  border-color: #6758F0;
  z-index: 3;
}`],
})
export class PortalCommunityListComponent implements OnInit {
    private list = [];
    private search_word = "";
    private login;
    private category = "";
    private _loading = false;

    private page = {
        current: 1,
        start: 1,
        end: 1,
    };

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         public route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();

        this.category = WizRoute.segment.category;
        await this.load();

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.category = WizRoute.segment.category;
                await this.move(1);
            }
        })
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {
        if (this.category === undefined) {
            return;
        }

        await this.loading(true);
        let { code, data } = await wiz.call('load', { page: this.page.current, category: this.category, text: this.search_word });
        const { list, lastpage, login } = data;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.list = list;
        this.login = login;
        if (this.category == 'qna' && Object.keys(this.service.auth.session).length !== 0) this.login = true;

        await this.loading(false);
        await this.service.render();
    }

    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.", "error");
            window.location.reload();
        }
        await this.load();
    }

    private async move(index: number) {
        this.page.current = index;
        await this.load();
    }

    private async show(id) {
        this.service.href('/community/' + this.category + '/view/' + id);
        // if (this.login) {
        //     this.service.href('/community/' + this.category + '/view/' + id);
        // }
        // else {
        //     alert('로그인 후 이용가능한 서비스 입니다');
        // }
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalCommunityListComponent;