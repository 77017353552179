import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.list.application');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import $ from 'jquery';

@Component({
    selector: 'wiz-portal-apply-list-application',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.apply.list.application/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.overflow-x {
  border-radius: none;
  box-shadow: none;
}

.table-responsive {
  max-width: 1440px;
  margin: 0 auto;
}

.col-auto {
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row .form-select {
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  width: 132px;
  height: 55px;
  font: normal normal normal 16px/18px NanumSquareOTF;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  text-align: center;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.header .text-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
}
.header .text-container h2 {
  font: normal normal bold 50px/58px NanumSquareOTF;
}
.header .text-container span {
  font: normal normal normal 15px/18px NanumSquareOTF;
}

.content {
  min-height: 900px;
  padding: 60px 25px 25px 25px;
  text-align: center;
  align-items: center;
}

.content-container {
  max-width: 1280px;
  display: flex;
  justify-content: center;
}

.sticky-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 8px 48px;
  position: sticky;
}

.btn {
  height: 60px;
  width: 179px;
  border-bottom: #5263FF 1px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.bg-indigo {
  color: white;
}

.search {
  background: white;
}
.search i {
  width: 18px;
  height: 18px;
  color: white;
}
.search .form-control {
  border-radius: 10px 0 0 10px;
  width: 349px;
  height: 55px;
  border: none;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  padding-left: 25px;
  z-index: 3;
  color: var(--unnamed-color-6d6d6d);
  text-align: left;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-nanumsquareotf);
  box-shadow: none;
}
.search .btn-search {
  width: 59px;
  height: 55px;
  border: none;
  background: #5263FF 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  z-index: 3;
}

.featured-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px 90px;
  border-radius: 10px;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .featured-image {
    height: 200px;
    margin: 0;
    margin-bottom: 32px;
  }
}

.card {
  width: 1440px;
  margin: 0 auto;
  border: none;
}

.table-first {
  width: 100%;
  margin: 0 auto;
}
.table-first thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 18px/21px NanumSquareOTF;
  height: 51px;
}
.table-first thead th {
  min-width: 7rem;
  font: normal normal bold 18px/21px NanumSquareOTF;
  padding-left: 10px;
}
.table-first tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-first tbody tr td {
  height: 120px;
  min-width: 7rem;
  font: normal normal normal 18px/21px NanumSquareOTF;
  text-align: left;
}
.table-first tbody tr .title {
  font: normal normal bold 20px/23px NanumSquareOTF;
}

.table-last {
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  min-width: 6rem;
  font: normal normal bold 17px/19px NanumSquareOTF;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
  text-align: left;
}

h4 {
  color: white;
}

.tabs {
  margin: 0 auto;
  height: 60px;
  width: 800px;
  overflow-x: auto;
}

.tabs .tab-header {
  height: 50px;
  display: flex;
  align-items: center;
}

.tabs .tab-header > div {
  width: 200px;
  text-align: center;
  color: #888;
  cursor: pointer;
  font: normal normal normal 20px/20px NanumSquareOTF;
  text-transform: uppercase;
  outline: none;
}

.tabs .tab-header > div > i {
  display: block;
  margin-bottom: 5px;
}

.tabs .tab-header > div.active {
  color: #5263FF;
  font: normal normal bold 25px/28px NanumSquareOTF;
}
@media (max-width: 810px) {
  .tabs .tab-header > div.active {
    word-break: keep-all;
  }
}

.tabs .tab-indicator {
  position: relative;
  width: 25%;
  height: 4px;
  background: #5263FF;
  left: 0px;
  border-radius: 999px;
  transition: all 500ms ease-in-out;
}`],
})
export class PortalApplyListApplicationComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public status: any = 'all'
    public category: any = 'noti';
    public indicatorPosition: string = '0%';
    public items: any = [];
    public loaded: boolean = false;

    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };
    private search_word = "";

    public async ngOnInit() {
        await this.service.init();
        await this.pageLoad(1);
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async load(status = this.status, category = this.category) {
        const { code, data } = await wiz.call("search", { page: this.pagenation.page, status, category, text: this.search_word });
        if (code != 200) return;

        let { rows, lastpage } = data;
        this.items = rows;

        this.pagenation.start = (parseInt(this.pagenation.page / 11) * 10) + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.");
        }
        this.pagenation.page = 1;
        await this.load();
    }
    private async move(index: number) {
        this.pagenation.page = index;
        await this.load();
    }

    public async changeStatus(newStatus: number) {
        this.search_word = '';
        this.pagenation.page = 1;
        await this.load(newStatus, this.category);
    }

    public async changeCategory(index: number, newCategory: string) {
        this.category = newCategory;
        this.status = 'all';
        this.pagenation.page = 1;
        this.search_word = '';
        this.indicatorPosition = `calc(calc(100% / 4) * ${index})`;
        this.service.render();


        await this.load(this.status, this.category);
    }

    public desc(item: any) {
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        text = $(text).text();
        if (text.length > 200) text = text.substring(0, 198) + '...';
        return text;
    }
    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }
}

export default PortalApplyListApplicationComponent;