import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.comment');

import { OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Service } from "src/libs/portal/season/service";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import toastr from 'toastr';

@Component({
    selector: 'wiz-component-comment',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.comment/view.scss */
h2 {
  padding-top: 51px;
  padding-bottom: 10px;
  margin: 0 auto;
  width: 95%;
  font: normal normal bold 20px/23px NanumSquareOTF;
}

hr {
  margin: 0;
  border: 1px solid #D1D1D1;
}

.file-list {
  margin: 0 auto;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  margin-right: auto;
  width: 95%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.file-list a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}

li {
  width: 95%;
  margin: 0 auto;
}

.comment-box {
  width: 95%;
  min-height: 10%;
  padding: 0px;
  margin: 0 auto;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  text-align: left;
}
.comment-box .input-group {
  box-shadow: none;
}
.comment-box .input-group textarea {
  width: 100%;
  border: none;
  text-align: left;
}
.comment-box .input-group form {
  width: 1380px;
  height: 100%;
}
.comment-box .text-len {
  color: #5D5D5D;
  font: normal normal normal 18px/21px NanumSquareOTF;
  display: flex;
  align-items: center;
}
.comment-box #file-upload {
  height: 40px;
}
.comment-box .btn-upload {
  width: 92px;
  height: 44px;
  border: none;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #6D6D6D;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.box {
  width: 99%;
  height: 50px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

label {
  margin-left: auto;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

.edit-group {
  width: 95%;
  padding: 0px;
  margin: 0 auto;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  text-align: left;
}
.edit-group textarea {
  width: 100%;
  border: none;
  text-align: left;
  box-shadow: none;
}
.edit-group form {
  width: 1380px;
  height: 100%;
}
.edit-group .file-box {
  margin: 0 auto;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  margin-right: auto;
  width: 95%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.edit-group .file-box a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}
.edit-group .text-len {
  color: #5D5D5D;
  font: normal normal normal 18px/21px NanumSquareOTF;
  display: flex;
  align-items: center;
}
.edit-group #file-upload {
  height: 40px;
}
.edit-group .btn-upload {
  height: 44px;
  border: none;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #6D6D6D;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: 10px;
}
.edit-group .btn-div {
  width: 100%;
  display: flex;
  justify-content: end;
}
.edit-group .btn-div .btn-edit {
  width: 92px;
  height: 44px;
  border: none;
  margin-right: 5px;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

div {
  border: none;
}
div .file-box {
  margin-left: 5px;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: auto;
  width: 100%;
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
div .file-box a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}

.filebox .upload-name {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  border: 1px solid #dddddd;
  width: 78%;
  color: #999999;
}

.filebox label {
  color: #fff;
  vertical-align: middle;
  background-color: none;
  cursor: pointer;
  height: 100;
  margin-left: 10px;
}

.filebox input[type=file] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

.border-line {
  width: 95%;
}

.comment-info {
  height: 36px;
}

.btn {
  border: none;
  background-color: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #6D6D6D;
  box-shadow: none;
}

.btn-remove {
  margin-left: auto;
  border: none;
  background-color: #F6F9FE;
  margin-right: 5px;
}

.btn-check {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #6D6D6D;
  border: 1px solid #D1D1D1;
  width: 60px;
  border-radius: 0px 0px 10px 0px;
  height: 34px;
}

.comment .comment-name {
  margin-top: 52px;
  font: normal normal bold 20px/23px NanumSquareOTF;
}
.comment .comment-content {
  margin-top: 10px;
  margin-left: 5px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

textarea {
  resize: none;
}

.btn-del {
  background: #ee5914;
  color: white;
}
.btn-del:hover {
  border-color: black;
}`],
})
export class ComponentCommentComponent implements OnInit {
    private comments = [

    ];
    private replies = [];
    private index = '';
    private post = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };
    private edit = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };
    private text = "";
    private plustext = "";
    private debouncer = new Subject<string>();
    public fd = new FormData();
    private login = {};
    @Input() submit_id: any;

    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };

    public async ngOnInit() {
        await this.service.init();
        this.load();
    }
    constructor(@Inject( Service) public service: Service) {
        this.debouncer.pipe(debounceTime(30)).subscribe(() => this.upload());
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {

        if (this.submit_id) {
            this.post.submit_id = this.submit_id
            this.edit.submit_id = this.submit_id;
        }

        this.login = this.service.auth.session
        const { code, data } = await wiz.call('load', { submit_id: this.submit_id, page: this.pagenation.page });
        if (code != 200) return;

        let { rows, replies, lastpage } = data;
        this.comments = rows;
        for (let i = 0; i < this.comments.length; i++) {
            if (this.comments[i].files.length > 0) {
                this.comments[i].files = JSON.parse(rows[i].files.replace(/'/g, '"'));
            }

        }

        this.pagenation.start = (parseInt(this.pagenation.page / 11) * 10) + 1;;
        this.pagenation.end = lastpage;
        this.loaded = true;

        await this.service.render();
    }
    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }
    private async onEnter() {
        this.debouncer.next();
    }

    public async fileId(i, index) {
        this.index = i;
        this.edit.id = index;
    }

    public async fileUpload(e) {
        if (this.edit.id != '') {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i]
                if (!file.filepath) file.filepath = file.name;
                this.fd.append('file[]', file);
                this.edit.files.push(file.filepath);
            }
        } else {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i]
                if (!file.filepath) file.filepath = file.name;
                this.fd.append('file[]', file);
                this.post.files.push(file.filepath);
            }
        }

        await this.service.render();
    }

    public async removeFile(id, index) {
        this.post.id = id
        for (let i = 0; i < this.comments.length; i++) {
            let comment = this.comments[i]
            if (comment['id'] == id) {
                comment.files.splice(index, 1);
                await this.service.render();
            }
        }

    }
    public async fileRemove(id, index) {
        this.post.id = id
        this.post.files.splice(index, 1)

        await this.service.render();
    }

    private async upload(index: number) {

        if (this.submit_id) {
            this.post.submit_id = this.submit_id
        }

        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }
        if (this.text == "") {
            toastr.warning("내용을 입력해주세요");
            return;
        }


        this.post.content = this.text;

        let post = this.post
        this.fd.append("data", JSON.stringify(post))

        let url = wiz.url('upload')
        const { code, data } = await this.service.file.upload(url, this.fd);

        if (code != 200) {
            await this.alert("잘못된 접근입니다.", "error");
        }
        this.text = '';
        this.post = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };
        this.index = undefined
        this.fd = new FormData();

        let el = document.getElementsByClassName('form-control');

        for (let i = 0; i < el.length; i++) {
            el[i].value = "";
        }

        await this.load();
    }


    private async del(id: number) {
        let res = await this.alert("댓글을 삭제하겠습니까?", "error");

        if (!res)
            return;

        let { code, data } = await wiz.call('delete', { id });
        await this.load();
    }

    private async editFunc(index: number, id: number) {
        this.post.files = [];
        this.edit.files = [];

        for (let i = 0; i < this.comments.length; i++) {
            if (i !== index && this.comments[i].status == 'edit') {
                this.comments[i].status = '';
            }
            this.comments[i].plus_status = '';
        }

        if (this.comments[index].status !== 'edit') {
            this.comments[index].status = 'edit';
        } else {
            this.comments[index].status = '';
        }

        this.text = '';

        // 서비스 호출
        await this.service.render();
    }

    private async update(index: number, id: number, text: string) {

        if (this.submit_id) {
            this.edit.submit_id = this.submit_id
        }

        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }

        this.edit.id = id;
        this.edit.content = text;
        this.edit.files.push(...this.comments[index].files)

        let edit = this.edit
        this.fd.append("data", JSON.stringify(edit))

        this.comments[index].status = '';
        this.comments[index].files = this.edit.files.slice()

        let url = wiz.url('update')
        const { code, data } = await this.service.file.upload(url, this.fd);

        this.edit = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };



        this.fd = new FormData();

        await this.service.render();
    }


    private async plusStatus(index: number) {


        for (let i = 0; i < this.comments.length; i++) {
            if (this.comments[i].status == 'edit') {
                this.comments[i].status = '';
                this.fd = new FormData();
            }
        }

        if (this.comments[index].plus_status == '') {
            this.comments[index].plus_status = 'plus';
        }
        else {
            this.comments[index].plus_status = '';
        }

        await this.service.render();
    }


    private async plus(id: number) {

        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }
        if (this.plustext == "") {
            toastr.warning("내용을 입력해주세요");
            return;
        }

        this.post.content = this.plustext;
        this.post.parent_id = id;
        let { code } = await wiz.call('upload', this.post);

        if (code != 200) {
            await this.alert("잘못된 접근입니다.", "error");
        }
        this.plustext = '';

        let el = document.getElementsByClassName('form-control');

        for (let i = 0; i < el.length; i++) {
            el[i].value = "";
        }

        await this.load();

    }
}

export default ComponentCommentComponent;