import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.reply');
import { OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Service } from "src/libs/portal/season/service";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import toastr from 'toastr';

@Component({
    selector: 'wiz-component-reply',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.reply/view.scss */
hr {
  margin: 0;
  border: 1px solid #D1D1D1;
}

img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.file-list {
  margin: 0 auto;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  margin-right: auto;
  width: 95%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.file-list a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}

.comment-box {
  width: 95%;
  min-height: 10%;
  padding: 0px;
  margin: 0;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  text-align: left;
}
.comment-box .input-group {
  box-shadow: none;
}
.comment-box .input-group textarea {
  width: 100%;
  border: none;
  text-align: left;
}
.comment-box .input-group form {
  width: 1380px;
  height: 100%;
}
.comment-box .text-len {
  color: #5D5D5D;
  font: normal normal normal 18px/21px NanumSquareOTF;
  display: flex;
  align-items: center;
}
.comment-box div {
  border: none;
}
.comment-box #file-upload {
  height: 40px;
}
.comment-box .btn-upload {
  width: 92px;
  height: 44px;
  border: none;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #6D6D6D;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

div {
  border: none;
}
div .file-box {
  margin: 0 auto;
  margin-left: 5px;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: auto;
  width: 100%;
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
div .file-box a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}

label {
  margin-left: auto;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#reply-file {
  display: none;
}

.edit-group {
  width: 95%;
  padding: 0px;
  margin: 0;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  text-align: left;
}
.edit-group textarea {
  width: 100%;
  border: none;
  text-align: left;
  box-shadow: none;
}
.edit-group .file-box {
  margin: 0 auto;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: auto;
  width: 95%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.edit-group .file-box a {
  text-decoration: underline;
  background: #F6F9FE 0% 0% no-repeat padding-box;
}
.edit-group form {
  width: 1380px;
  height: 100%;
}
.edit-group #file-upload {
  height: 40px;
}
.edit-group .btn-upload {
  height: 44px;
  border: none;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #6D6D6D;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.edit-group .text-len {
  color: #5D5D5D;
  font: normal normal normal 18px/21px NanumSquareOTF;
  display: flex;
  align-items: center;
}
.edit-group .btn-div {
  width: 100%;
  display: flex;
  justify-content: end;
}
.edit-group .btn-div .btn-edit {
  width: 92px;
  height: 44px;
  border: none;
  margin-right: 5px;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.filebox .upload-name {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  border: 1px solid #dddddd;
  width: 78%;
  color: #999999;
}

.filebox label {
  color: #fff;
  vertical-align: middle;
  background-color: none;
  cursor: pointer;
  height: 100;
  margin-left: 10px;
}

.filebox input[type=file] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

.box {
  min-width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.border-line {
  width: 95%;
}

.comment-info {
  height: 36px;
}

.btn {
  border: none;
  background-color: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #6D6D6D;
  box-shadow: none;
}

.btn-remove {
  margin-left: auto;
  border: none;
  background-color: #F6F9FE;
  margin-right: 5px;
}

.btn-check {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #6D6D6D;
  border: 1px solid #D1D1D1;
  width: 60px;
  border-radius: 0px 0px 10px 0px;
  height: 34px;
}

.comment .comment-name {
  margin-top: 52px;
  font: normal normal bold 20px/23px NanumSquareOTF;
}
.comment .comment-content {
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

textarea {
  resize: none;
}

.btn-del {
  background: #ee5914;
  color: white;
}
.btn-del:hover {
  border-color: black;
}`],
})
export class ComponentReplyComponent implements OnInit {
    @Input() pcomment: any;
    @Input() submit_id: any;
    @Input() replies: any;
    private recomments = [];

    private index: number;
    private loaded = false;
    private login = {};
    private reply = { id: "", submit_id: "", content: "", parent_id: "", files: [] };
    private text = "";
    private debouncer = new Subject<string>();
    public fd = new FormData();
    public async ngOnInit() {
        await this.service.init();
        this.load();
    }
    constructor(@Inject( Service) public service: Service) {
        this.debouncer.pipe(debounceTime(30)).subscribe(() => this.upload());
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {
        if (this.submit_id) {
            this.reply.submit_id = this.submit_id;
        }

        if (this.pcomment) {
            this.reply.parent_id = this.pcomment.id;
        }



        this.loaded = false;
        await this.service.render();
        this.login = this.service.auth.session
        let { code, data } = await wiz.call('load', { parent_id: this.pcomment.id });
        this.recomments = data;

        for (let i = 0; i < this.recomments.length; i++) {
            this.recomments[i].files = JSON.parse(data[i].files.replace(/'/g, '"'));
        }
        this.loaded = true;
        await this.service.render();
    }

    private async onEnter() {
        this.debouncer.next();
    }


    private async upload() {

        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }
        if (this.text == "") {
            toastr.warning("내용을 입력해주세요");
            return;
        }

        this.reply.content = this.text;
        this.reply.parent_id = this.pcomment.id;
        let reply = this.reply;
        this.fd.append("data", JSON.stringify(reply));

        let url = wiz.url('upload');
        const { code, data } = await this.service.file.upload(url, this.fd);
        // await this.service.render();

        if (code != 200) {
            await this.alert("잘못된 접근입니다.", "error");
        }


        this.pcomment.plus_status = '';
        this.text = '';
        this.reply = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };
        this.index = undefined
        this.fd = new FormData();

        let el = document.getElementsByClassName('form-control');

        for (let i = 0; i < el.length; i++) {
            el[i].value = "";
        }

        await this.load();
    }

    public async fileId(i, index) {
        this.index = i;
        this.reply.id = index;
    }
    public async fileUpload(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i]
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.reply.files.push(file.filepath);
        }

        await this.service.render();
    }

    public async removeFile(id, index) {
        this.reply.id = id

        for (let i = 0; i < this.recomments.length; i++) {
            let comment = this.recomments[i]
            if (comment['id'] == id) {
                comment.files.splice(index, 1);
                await this.service.render();
            }
        }
    }

    public async fileRemove(id, index) {
        this.post.id = id
        this.post.files.splice(index, 1)
    }
    private async del(id: number) {
        let res = await this.alert("댓글을 삭제하겠습니까?", "error");

        if (!res)
            return;

        let { code, data } = await wiz.call('delete', { id });
        await this.load();
    }

    private async edit(index: number, id: number) {
        this.reply.files = [];

        for (let i = 0; i < this.recomments.length; i++) {
            if (i !== index && this.recomments[i].status == 'edit') {
                this.recomments[i].status = '';
            }
        }

        this.reply = { id: "", submit_id: "", content: "", parent_id: "", files: [] };
        this.fd = new FormData();

        if (this.recomments[index].status !== 'edit') {
            this.recomments[index].status = 'edit';
        } else {
            this.recomments[index].status = '';
        }

        this.text = '';
        await this.service.render();

    }

    private async update(index: number, id: number, text: string) {

        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }
        this.reply.parent_id = this.pcomment.id
        this.reply.id = id;
        this.reply.files.push(...this.recomments[index].files)
        this.reply.content = text;

        let reply = this.reply
        this.fd.append("data", JSON.stringify(reply))

        this.recomments[index].status = '';
        this.recomments[index].files = this.reply.files.slice()
        let url = wiz.url('update')
        const { code, data } = await this.service.file.upload(url, this.fd);


        this.reply = { id: "", submit_id: "", content: "", parent_id: '', page: "", files: [] };
        this.fd = new FormData();


        await this.service.render();
    }

}

export default ComponentReplyComponent;