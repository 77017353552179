import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.dashboard/view.scss */
.opt-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.opt-area .btn {
  margin: 0 4px;
  border-radius: 30px;
  height: auto;
  font: normal normal normal 16px Montserrat;
}
.opt-area .btn-opt {
  padding: 12px 30px;
  border: 1px solid #72787F;
}
.opt-area .btn-opt:hover {
  color: #5263FF;
  border: 1px solid #5263FF;
  background-color: #b5bcff;
}
.opt-area .btn-opt.active {
  background-color: #5263FF;
  color: #FFF;
  border: 1px solid #5263FF;
}

.map-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 18px 24px;
  background: var(--wc-background);
  border-radius: 24px;
  max-width: 1280px;
  margin: 0 auto;
  overflow-y: auto;
}

.widget-area {
  width: 100%;
  max-width: 420px;
  padding-left: 24px;
  height: 100%;
  overflow: auto;
}
@media (max-width: 1024px) {
  .widget-area {
    padding-left: 16px;
  }
}
@media (max-width: 768px) {
  .widget-area {
    padding-left: 8px;
  }
  .widget-area .opt-area {
    flex-direction: column;
    align-items: flex-start;
  }
  .widget-area .btn-opt {
    width: 100%;
    margin-bottom: 8px;
    padding: 8px;
  }
}
@media (max-width: 480px) {
  .widget-area .opt-area .btn-opt {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.card {
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
}
.card .card-header {
  padding: 8px 16px;
}
.card .card-body {
  padding: 12px 24px;
  border-top: none !important;
}
@media (max-width: 768px) {
  .card {
    margin-bottom: 16px;
  }
  .card .card-body {
    padding: 8px 12px;
  }
}
@media (max-width: 480px) {
  .card .card-body {
    padding: 8px 8px;
  }
}

.map-area {
  flex: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--wc-background-dark);
  padding: 24px;
  border-radius: 24px;
  overflow: auto;
}
.map-area .map {
  flex: auto;
  width: 100%;
  height: 100%;
}

.input-icon .form-control.form-control-rounded {
  border: 1px solid var(--wc-blue);
  border-radius: 8px;
}
.input-icon .input-icon-addon {
  color: var(--wc-blue);
}

.progress {
  height: 16px;
  border-radius: 8px;
}`],
})
export class PageDashboardComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public traffic: any = null;
    public trafficName = "대전";
    public node = [];
    public sample = {
        series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
        }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
        }]
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public tab: string = 'line';

    public async switchTab(tab: string) {
        this.tab = tab;
        await this.service.render();
    }

    public event: any = {
        data: {},
        selected: async (location) => {
            this.trafficName = location.name;
            this.traffic = null;
            await this.service.render();

            const { code, data } = await wiz.call("loadTraffic", { id: location.id });
            let traffic = data.traffic;
            this.node = data.node;
            console.log(this.node, '디스 노드')
            if (traffic) {
                for (let i = 0; i < traffic._time.length; i++)
                    traffic._time[i] = (moment(traffic._time[i]).add(9, 'hours')).format("YYYY-MM-DD HH:mm:ss");

                this.traffic = {
                    category: traffic._time,
                    series: [{
                        name: 'IN',
                        data: traffic._in
                    }, {
                        name: 'OUT',
                        data: traffic._out
                    }]
                };
            }
        }
    };

}

export default PageDashboardComponent;