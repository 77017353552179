import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.user/view.scss */
.navbar {
  background: #fff;
  /* Layout Properties */
  height: 80px;
  /* UI Properties */
  opacity: 1;
  display: flex;
  justify-content: center;
}
.navbar .collapse {
  display: flex;
  justify-content: center;
}
.navbar li {
  border: none;
}
.navbar .section {
  display: flex;
  width: 1920px;
  justify-content: space-between;
  margin: 0 auto;
}
.navbar .section .container:first-child {
  min-height: 52px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navbar .section .container:last-child {
  min-height: 52px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .navbar-brand img {
  height: 36px;
}
.navbar .navbar-brand span {
  color: var(--wc-gray);
  font-size: 18px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
.navbar .nav-item {
  border: none;
}
.navbar .nav-item.active {
  font: normal normal bold 18px/21px NanumSquareOTF;
  border: none;
  color: #5263FF;
}
.navbar .nav-item.active::after {
  font: bold 18px/21px NanumSquareOTF;
  border: none;
  color: #5263FF;
}
.navbar .nav-item .nav-link {
  color: var(--wc-text);
}
.navbar .nav-item a {
  cursor: pointer;
  text-align: center;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}
.navbar .nav-item .btn {
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
  .navbar {
    min-height: 0;
  }
  .navbar .section .container {
    min-height: 0;
  }
  .navbar .navbar-brand span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbar .section-sub {
    background: var(--wc-blue);
  }
  .navbar .section-sub .nav-link {
    color: #fff;
  }
  .navbar .section-sub .navbar-collapse.collapse.show {
    height: 100vh;
  }
}

.dropdown-menu {
  top: 48px;
  right: 0;
}

.v-center {
  display: flex;
  align-items: center;
}`],
})
export class ComponentNavUserComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public async collapse() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        await this.service.render();
    }
    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

}

export default ComponentNavUserComponent;