import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.works.message.body');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-works-message-body',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.works.message.body/view.scss */
.card-body {
  display: flex;
}
.card-body .message-icon {
  width: 56px;
}
.card-body .message-body {
  flex: 1;
}
.card-body .message-body .message-header {
  display: flex;
  margin-bottom: 4px;
}
.card-body .message-body .message-header .message-title {
  font-family: "main-eb";
}
.card-body .message-body .message-header span i,
.card-body .message-body .message-header .favorite i {
  cursor: pointer;
}
.card-body .message-body .message-header span i:hover,
.card-body .message-body .message-header .favorite i:hover {
  color: var(--wc-blue);
}

.message-body {
  width: 100%;
}

.message-text {
  word-break: break-all;
  width: 100%;
  position: relative;
}

.is-reply {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 36px;
  border-top: 1px solid var(--wc-border);
}

.fa-reply {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.message-files {
  margin-bottom: 6px;
}
.message-files .btn {
  padding: 2px 8px;
  margin-right: 6px;
  margin-top: 4px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.message-file-container {
  padding: 8px;
  background-color: #fff;
  border-top: 1px solid var(--wc-border);
}
.message-file-container .btn {
  margin: 2px 4px;
  padding: 2px 8px;
}

.message-image-container {
  border-top: 1px solid var(--wc-border);
  background: #fff;
}

.image-box {
  background-color: var(--wc-gray-light);
}

.message-img {
  display: block;
  position: relative;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.message-img:hover {
  border: 4px solid var(--wc-blue);
  cursor: pointer;
}
.message-img .btn.btn-red.btn-sm {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 4px 8px;
}`],
})
export class PortalWorksMessageBodyComponent implements OnInit {
    @Input() cache: any = {};
    @Input() event: any = {};
    @Input() project: any = {};
    @Input() msg: any = {};

    public async ngOnInit() {
    }
}

export default PortalWorksMessageBodyComponent;