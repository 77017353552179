import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.works.project.drive');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-portal-works-project-drive',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.works.project.drive/view.scss */
.drive-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #fff;
}
.drive-container .drive-header {
  display: block;
  color: var(--wc-blue);
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--wc-border);
  border-right: 1px solid var(--wc-border);
}
.drive-container .drive-header .drive-header-container {
  display: flex;
  padding: 12px 16px;
  text-align: left;
  background-color: var(--wc-blue-light);
}
.drive-container .drive-header .drive-header-container .avatar-area {
  width: 32px;
  display: flex;
  align-items: center;
}
.drive-container .drive-header .drive-header-container .drive-title {
  display: flex;
  flex: auto;
  align-items: center;
  width: 230px;
  font-size: 18px;
  font-family: "main-eb";
  word-break: break-all;
  white-space: break-spaces;
  padding-left: 8px;
}
.drive-container .drive-header .drive-header-container .action {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}
.drive-container .drive-header .drive-header-search {
  display: flex;
  padding: 6px 12px;
  background-color: var(--wc-blue-light);
}
.drive-container .drive-body {
  display: flex;
  flex: auto;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.drive-container .drive-body .drive-aside {
  display: flex;
  flex-direction: column;
  width: 320px;
  border-right: 1px solid var(--wc-border);
  height: 100%;
  overflow: auto;
  background: var(--wc-light);
}
.drive-container .drive-body .drive-aside .aside-header {
  padding: 8px 12px;
  cursor: pointer;
}
.drive-container .drive-body .drive-aside .aside-header.active {
  color: var(--wc-blue);
}
@media (max-width: 768px) {
  .drive-container .drive-body .drive-aside {
    display: none;
  }
}
.drive-container .drive-body .attachment-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
  border-right: 1px solid var(--wc-border);
}
.drive-container .drive-body .attachment-content .file-list {
  flex: auto;
  overflow: auto;
}
.drive-container .drive-body .attachment-content .file-list a {
  color: var(--wc-dark);
}
.drive-container .drive-body .attachment-content .photo-list {
  flex: auto;
  overflow: auto;
}
.drive-container .drive-body .attachment-content .photo-list .photo-item {
  background-color: var(--wc-light);
  cursor: pointer;
  position: relative;
}
.drive-container .drive-body .attachment-content .photo-list .photo-item .img-action {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.drive-container .drive-body .attachment-content .photo-list .photo-item:hover .img-action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.drive-container .drive-body .attachment-content .pagenation {
  padding: 4px 0;
  border-top: 1px solid var(--wc-border);
}
.drive-container .drive-body .drive-content {
  flex: auto;
  height: 100%;
  overflow: auto;
  border-right: 1px solid var(--wc-border);
}
.drive-container .drive-body .drive-content .file-title {
  display: flex;
  align-items: center;
  max-width: 480px;
}
.drive-container .drive-body .drive-content .file-title i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  font-size: 20px;
  color: var(--wc-gray);
}
.drive-container .drive-body .drive-content .file-title i.fa-folder {
  color: #A1C9F7;
}
.drive-container .drive-body .drive-content .file-title span {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drive-container .drive-body .drive-content .text-muted {
  font-size: 12px;
}
.drive-container .drive-body .drive-content .select-btns {
  padding-right: 0;
}
.drive-container .drive-body .drive-content .action-btns {
  padding-left: 4px;
}
.drive-container .drive-body .drive-content .action-btns,
.drive-container .drive-body .drive-content .select-btns {
  min-width: 49px;
  white-space: nowrap;
}
.drive-container .drive-body .drive-content .action-btns .badge,
.drive-container .drive-body .drive-content .select-btns .badge {
  display: none;
  cursor: pointer;
}
.drive-container .drive-body .drive-content .action-btns .badge.bg-blue,
.drive-container .drive-body .drive-content .select-btns .badge.bg-blue {
  display: inline-block;
}
.drive-container .drive-body .drive-content tr:hover .action-btns .badge,
.drive-container .drive-body .drive-content tr:hover .select-btns .badge {
  display: inline-block;
  width: 32px;
}

.drive-tree {
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}

.tree-node {
  width: 100%;
  display: flex;
  cursor: pointer;
}
.tree-node i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--wc-gray);
}
.tree-node .tree-node-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
}
.tree-node .form-control {
  height: 20px !important;
  font-size: 12px;
  border-radius: 4px;
  text-align: left;
}
.tree-node i.fa-folder,
.tree-node i.fa-folder-open {
  color: #A1C9F7;
}
.tree-node i.icon-action-always {
  margin-left: 6px;
  color: var(--wc-blue);
}
.tree-node i.icon-action {
  display: none;
  margin-left: 6px;
}
.tree-node.active {
  color: var(--wc-blue);
  background: var(--wc-blue-light);
}
.tree-node:hover {
  color: var(--wc-blue);
  background: var(--wc-blue-light);
}
.tree-node:hover i.icon-action {
  display: inline-block;
  color: var(--wc-gray);
}
.tree-node:hover i.icon-action:hover {
  color: var(--wc-blue);
}

.tree-root {
  padding-left: 16px;
}

.tree-icon {
  width: 20px;
  text-align: center;
}

.alert-uploading {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 100%;
  max-width: 260px;
  background-color: #fff;
}

@media (max-width: 768px) {
  table > tbody > tr * {
    word-wrap: break-word;
    white-space: break-spaces;
  }
}`],
})
export class PortalWorksProjectDriveComponent implements OnInit, OnDestroy {
    @Input() readOnly: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( Project    )         public project: Project    ) { }

    public async ngOnInit() {
        await this.service.init();
        while (!this.treeConfig.rootNode)
            await this.service.render(100);
        this.current = this.treeConfig.rootNode();
        await this.service.render();
    }

    public async alert(message: string, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: "취소",
            actionBtn: "error",
            action: action,
            status: "error"
        });
    }

    public accessLevel() {
        if (this.readOnly) return false;
        return this.project.accessLevel(['admin', 'manager'])
    }

    public tab: string = 'files';

    public async tabChange(tab: string = 'files') {
        this.tab = tab;
        if (tab == 'attachment') {
            this.attachment.ns = '.attachment';
            await this.loadAttachment(1);
        } else if (tab == 'photo') {
            this.attachment.ns = '.image';
            await this.loadAttachment(1);
        }

        await this.service.render();
    }

    public attachment: any = {
        list: [],
        page: 1,
        start: -1,
        end: -1,
    };

    public async loadAttachment(page: number) {
        this.attachment.list = [];
        this.attachment.page = page;
        await this.service.render();
        const { code, data } = await this.project.api(`attachment/list`, this.attachment);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.attachment.list = rows;
        this.attachment.start = startpage;
        this.attachment.end = lastpage;
        await this.service.render();
    }

    public imageUrl(image: any, isThumbnail: boolean = true) {
        if (isThumbnail)
            return `url('${this.project.thumbnailUrl(image.id, image.filename)}')`;
        return this.project.downloadUrl(image.id, image.filename);
    }

    // 파일 드라이브
    public current: any;
    public selected: any = [];
    public newFolder: any = {};

    public icon(node: any, checkopen: boolean = true) {
        if (node.type == 'folder') {
            if (node.isOpen() && checkopen)
                return 'fa-regular fa-folder-open';
            else
                return 'fa-solid fa-folder';
        }

        return 'fa-regular fa-file-lines';
    }

    public async open(node: any) {
        if (node.type == 'folder') {
            await this.tabChange('files');
            this.current = node;
            this.selected = [];
            this.newFolder = {};
            await this.current.refresh();
            await this.service.render();
        } else {
            await this.download(node);
        }
    }

    public async select(node: any) {
        if (this.selected.includes(node.id)) {
            this.selected.remove(node.id);
        } else {
            this.selected.push(node.id);
        }
        await this.service.render();
    }

    public async selectAll() {
        let childs = this.current.getChildren();
        let isUnselect = this.selected.length == childs.length;
        for (let i = 0; i < childs.length; i++) {
            let node = childs[i];
            if (isUnselect) {
                if (this.isSelected(childs[i]))
                    await this.select(childs[i]);
            } else {
                if (!this.isSelected(childs[i]))
                    await this.select(childs[i]);
            }
        }
        await this.service.render();
    }

    public isSelected(node: any) {
        return this.selected.includes(node.id);
    }

    public async deleteSelected() {
        if (!await this.alert(`${this.selected.length}개의 파일을 삭제하시겠습니까?`, `삭제`)) return;
        let data = JSON.stringify(this.selected);
        await this.project.api(`drive/deletes`, { data });
        this.selected = [];
        await this.current.refresh();
        await this.service.render();
    }

    public treeConfig: any = {
        load: async (path: any) => {
            let res = await this.project.api(`drive/tree`, { path: path });
            return res;
        },
        update: async (node: any) => {
            if (this.readOnly) return;
            let data = JSON.parse(JSON.stringify(node));
            let changed = data.title != data.rename;
            if (data.rename) data.title = data.rename;
            await this.project.api(`drive/update`, data);
            if (changed) await node.flush();
        },
        upload: async (node: any, files: any) => {
            if (this.readOnly) return;
            if (node.type == 'file')
                node = node.parent();
            this.project.drive.regist(node, files);
        },
        select: async (node: any) => {
            if (node.type == 'folder') {
                if (node.id != this.current.id)
                    await node.toggle();
            } else {
                await this.download(node);
            }
        },
        isActive: (node: any) => {
            if (!this.current) return false;
            return node.id === this.current.id;
        }
    }

    public async create(node: any, onTree: boolean = true) {
        if (onTree) {
            node.newItem = { type: 'folder', root_id: node.id ? node.id : '' };
        } else {
            this.newFolder = { type: 'folder', root_id: node.id ? node.id : '' };
        }
        await this.service.render();
    }

    public async createFolder(node: any, onTree: boolean = true) {
        let data: any = null;
        if (onTree) {
            data = JSON.parse(JSON.stringify(node.newItem));
            delete node.newItem;
        } else {
            data = JSON.parse(JSON.stringify(this.newFolder));
            this.newFolder = {};
        }
        if (!data.title) return;
        await this.project.api(`drive/create`, data);
        await node.refresh();
    }

    public async cancelCreate(node: any, onTree: boolean = true) {
        if (onTree) {
            delete node.newItem;
        } else {
            this.newFolder = {};
        }
        await this.service.render();
    }

    public async delete(node: any) {
        if (!await this.alert(`'${node.title}' ${node.type == 'folder' ? '폴더' : '파일'}을 삭제하시겠습니까?`, `삭제`)) return;
        await this.project.api(`drive/delete`, { id: node.id });
        this.selected.remove(node.id);
        await node.parent().refresh();
    }

    public async rename(node: any) {
        node.editable = true;
        await this.service.render();
    }

    public async download(node: any) {
        if (!node) node = this.rootNode;
        let url = this.project.url('drive/download/' + node.id);
        window.open(url, '_blank');
    }

    public async upload(node: any) {
        let files = await this.service.file.select();
        await this.project.drive.regist(node, files);
    }

    public dragToItem: any = null;

    public isDrop(node: any) {
        if (this.dragToItem && node.id == this.dragToItem.id)
            return true;
        return false;
    }

    public async dragover(event: any, file: any) {
        event.preventDefault();
        event.stopPropagation();
        let changed: boolean = this.dragToItem != file;
        this.dragToItem = file;
        if (changed)
            await this.service.render();
    }

    public async dragend(event: any, file: any) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.dragToItem) return;

        let parent = file.parent();
        file.root_id = this.dragToItem.id ? this.dragToItem.id : '';
        await file.update();
        await await parent.refresh();
        await await file.parent().refresh();

        this.dragToItem = null;
        await this.service.render();
    }

    public async preventEvent(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }
}

export default PortalWorksProjectDriveComponent;