import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.availability');
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import Handsontable from "handsontable";
import toastr from "toastr";

@Component({
    selector: 'wiz-portal-kreonetadm-availability',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.kreonetadm.availability/view.scss */
.handsontable-container {
  width: 100%;
  height: 100%;
  flex: auto;
  padding: 24px;
  max-width: 1180px;
  margin: 0 auto;
}

.container {
  height: max-content;
}

.buttons {
  width: 90%;
  display: flex;
  max-width: 1135px;
  padding-right: 2rem;
  justify-content: space-between;
  margin: 0 auto;
  background: none;
  border: none;
  text-align: right;
}

.form-control {
  padding: 8px;
}`],
})
export class PortalKreonetadmAvailabilityComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        this.year = new Date().getFullYear();
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
        await this.service.render();
    }

    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public year: number = 0;
    public listloaded: boolean = false;
    public status = 'availability';
    public keys = '';

    public async load() {
        if (this.year < 1000 || this.year > 10000) return;
        this.listloaded = false;
        await this.service.render();

        let { data } = await wiz.call("data", { year: this.year, status: this.status });

        this.listloaded = true;
        await this.service.render();
        if (this.status == 'availability') {
            let ctx = this.table.nativeElement;
            this.handsontable = new Handsontable(ctx, {
                data: data,
                height: '100%',
                colWidths: [120, 120, 80,
                    60, 60, 60, 60,
                    60, 60, 60, 60,
                    60, 60, 60, 60],
                rowHeights: 30,
                colHeaders: [
                    "FROM",
                    "TO",
                    "국내/국제",
                    "1월",
                    "2월",
                    "3월",
                    "4월",
                    "5월",
                    "6월",
                    "7월",
                    "8월",
                    "9월",
                    "10월",
                    "11월",
                    "12월"
                ],
                columns: [
                    { data: '_from', type: "text" },
                    { data: '_to', type: "text" },
                    { data: 'category', type: "dropdown", source: ['국내', '국제'] },
                    { data: 'm1', type: "numeric" },
                    { data: 'm2', type: "numeric" },
                    { data: 'm3', type: "numeric" },
                    { data: 'm4', type: "numeric" },
                    { data: 'm5', type: "numeric" },
                    { data: 'm6', type: "numeric" },
                    { data: 'm7', type: "numeric" },
                    { data: 'm8', type: "numeric" },
                    { data: 'm9', type: "numeric" },
                    { data: 'm10', type: "numeric" },
                    { data: 'm11', type: "numeric" },
                    { data: 'm12', type: "numeric" }
                ],
                dropdownMenu: true,
                hiddenColumns: {
                    indicators: true
                },
                contextMenu: true,
                multiColumnSorting: true,
                filters: true,
                rowHeaders: true,
                manualRowMove: true,
                minSpareRows: 1,
                licenseKey: "non-commercial-and-evaluation"
            });

        } else {

            let ctx = this.table.nativeElement;
            this.handsontable = new Handsontable(ctx, {
                data: data,
                height: '100%',
                colWidths: [145, 145, 145, 145, 145, 145, 145, 155],
                rowHeights: 30,
                colHeaders: [
                    '아이디',
                    "월",
                    "회선",
                    "장비",
                    "시설",
                    "전체",
                    "장애 건수",
                    "평균 복구시간"
                ],
                columns: [
                    { data: 'id', type: "numeric" }, // id 컬럼 추가
                    { data: 'month', type: "text" },
                    { data: 'line', type: "numeric" },
                    { data: 'equipment', type: "numeric" },
                    { data: 'facilites', type: "numeric" },
                    { data: 'total', type: "numeric" },
                    { data: 'error', type: "numeric" },
                    { data: 'recovery', type: "numeric" },
                ],
                dropdownMenu: true,
                hiddenColumns: {
                    indicators: true,
                    columns: [0] // id 컬럼이 0번 인덱스라면 숨기기
                },
                contextMenu: true,
                multiColumnSorting: true,
                filters: true,
                rowHeaders: true,
                manualRowMove: true,
                minSpareRows: 1,
                licenseKey: "non-commercial-and-evaluation"
            });
        }


    }
    public async statusChange(status: string) {
        this.status = status;
        await this.load();
    }
    public async update() {
        let data = this.handsontable.getData();
        if (this.status == 'availability') {
            this.keys = '_from,_to,category,m1,m2,m3,m4,m5,m6,m7,m8,m9,m10,m11,m12'.split(",");
        } else {
            this.keys = 'id,month,line,equipment,facilites,total,error,recovery'.split(",");
        }

        for (let i = 0; i < data.length; i++) {
            let tmp = {};
            for (let j = 0; j < data[i].length; j++)
                tmp[this.keys[j]] = data[i][j];
            data[i] = tmp;
        }
        console.log(data, '데이타 정보')
        await wiz.call("update", { data: JSON.stringify(data), year: this.year, status: this.status });
        toastr.success("저장되었습니다");
    }
}

export default PortalKreonetadmAvailabilityComponent;