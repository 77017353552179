import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.users');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-users',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.users/view.scss */
.nav-link {
  cursor: pointer;
  color: #5D5D5D;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.nav-link.active {
  color: #5263FF;
  font-weight: bold;
}
.nav-link ::after {
  content: "";
  display: block;
  color: #5263FF;
}

.d-none {
  gap: 0.5rem;
}

.text-reset {
  height: 50px;
  width: 50px;
  border: solid 1px;
  border-radius: 20px !important;
}

.container-xl {
  max-width: 1440px;
  padding: 0;
}

.navbar-brand {
  margin-right: 1rem;
  padding-left: 0.5rem;
}
@media (max-width: 992px) {
  .navbar-brand {
    margin-right: 0;
  }
}

.navbar-nav {
  padding-right: 0.5rem;
}`],
})
export class ComponentNavUsersComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public async collapse() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        await this.service.render();
    }
    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

}

export default ComponentNavUsersComponent;